@import 'fonts';
@import 'variables';
@import 'mixins';

// Vendor
@import '~bootstrap/scss/bootstrap';
@import '~spin.js/spin.css';

html,
body {
  overflow-x: hidden;
}

#app {
  font-family: $font-main;
  font-size: 16px;
  line-height: 1.375;
  @import 'utilities';
  @import 'base';
  @import 'page';
  @import 'global-landing';
  @import 'partners/united';
  @import 'partners/cathay';
  @import 'partners/singapore';
  @import 'partners/air-canada';
}

@import 'translated';

.global-header .icon-menu::before {
  color: $black;
}
